<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { sendPasswordResetEmail } from "firebase/auth";
import AuthService from "@/views/pages/Auth/Auth.service";
import ButtonBraip from "@/shared/components/ButtonBraip.vue";
import TextField from "@/shared/components/TextField.vue";
import MailOppened from "@/shared/components/icons/dark/MailOppened.vue";
import { useLoadingService } from "@/stores";

const router = useRouter();

const email = ref("");
const errorEmail = ref("");
const submitted = ref(false);
const loading = ref(false);
const disabled = ref(false);

const baseURL = ref("");
const loadingScreen = useLoadingService();

onMounted(() => {
  baseURL.value = import.meta.env.VITE_BASEURL;
  loadingScreen.disabledLoading();
});

function validEmail() {
  const emailRegex = /[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  if (!email.value) {
    errorEmail.value = "O campo é obrigatório.";
  } else if (!emailRegex.test(email.value)) {
    errorEmail.value = "O e-mail informado é inválido.";
  } else {
    errorEmail.value = "";
  }
}

function sendRecoveryPassword() {
  validEmail();
  if (errorEmail.value) return;
  loading.value = true;
  const actionCodeSettings = {
    url: `${baseURL.value}reset-password`,
  };

  sendPasswordResetEmail(
    AuthService.auth,
    email.value.trim(),
    actionCodeSettings
  )
    .then(() => {
      submitted.value = true;
    })
    .catch(() => {
      errorEmail.value = "Algo deu errado.";
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<template>
  <div
    class="flex flex-col min-h-full bg-top bg-no-repeat bg-cover bg-primary-600 bg-braip-login"
  >
    <main class="flex flex-col items-center justify-center flex-1 min-w-fit">
      <form
        class="flex flex-col max-w-md gap-12 p-10 mx-4 bg-white rounded-sm mt-xxl"
        @submit.prevent="sendRecoveryPassword"
        v-if="!submitted"
      >
        <div class="flex flex-col gap-14">
          <div class="flex items-center justify-between">
            <img
              class="w-20"
              src="@/assets/img/braip-logo.svg"
              alt="Logo da Braip"
            />
          </div>

          <div class="flex flex-col gap-nano">
            <h1 class="font-semibold text-dark-100 text-h7 font-poppins">
              Esqueceu a senha?
            </h1>
            <p class="text-light-700 text-p4">
              Digite o e-mail vinculado à sua conta no espaço abaixo e em
              seguida enviaremos um link para redefinir a sua senha.
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-xs">
          <div class="flex flex-col gap-xxs">
            <div class="flex flex-col gap-xxxs">
              <TextField
                label="E-mail"
                id="email"
                type="text"
                v-model="email"
                :error-message="errorEmail"
                @input="errorEmail = ''"
              />
            </div>
          </div>
          <div class="flex flex-col items-center gap-xxs">
            <ButtonBraip
              text="Enviar"
              type="submit"
              class="!justify-center"
              semibold
              full
              :disabled="disabled"
              :loading="loading"
            />
            <RouterLink to="/" class="font-medium text-p5 text-light-600">
              Cancelar
            </RouterLink>
          </div>
        </div>
      </form>
      <div
        class="flex flex-col max-w-md gap-12 p-10 mx-4 bg-white rounded-sm mt-xxl"
        v-else
      >
        <div class="flex flex-col gap-14">
          <div class="flex items-center justify-between">
            <img
              class="w-20"
              src="@/assets/img/braip-logo.svg"
              alt="Logo da Braip"
            />
          </div>
          <div class="flex justify-center">
            <div class="p-6 bg-linear-purple">
              <MailOppened class="fill-primary-600" :size="50" />
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-xs">
          <div class="flex flex-col gap-nano">
            <h1
              class="font-semibold text-center text-dark-100 text-h7 font-poppins"
            >
              E-mail enviado com sucesso!
            </h1>
            <p class="text-center text-light-700 text-p4">
              O link para redefinição de senha foi enviado para o seu e-mail.
            </p>
          </div>
          <ButtonBraip
            text="Concluir"
            type="button"
            class="!justify-center"
            semibold
            full
            :disabled="disabled"
            :loading="loading"
            @click="router.push('/')"
          />
        </div>
      </div>
    </main>
    <footer class="flex justify-center w-full">
      <p class="mx-4 text-center my-xs w-36 sm:w-full text-p6 text-light-0/40">
        © Braip 2024. Todos os direitos reservados
      </p>
    </footer>
  </div>
</template>

<style scoped></style>
